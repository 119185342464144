body {
  margin: 0;
  padding: 0;
  font-family: 'Nunito', sans-serif;
  font-size: 16px;
  color: #333;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  --red: #da4d49;
  --light-red: #f6d9d8;
  --navy: #003a74;
  --light-blue: #E4EBF8;
}

.max-width-form {
  max-width: 500px;
  margin-left: auto;
  margin-right: auto;
}

.player-signup-form-container {
  margin-top: 30px;
}

select.form-control,
textarea.form-control,
input.form-control {
  font-size: 16px;
}

.page-header {
  text-align: center;
}

.form-table {
  width: 100%;
}

.form-table td {
  padding-bottom: 10px;
}

.form-label {
  text-align: right;
  white-space: nowrap;
  padding-right: 10px;
  font-weight: bold;
}

.top-aligned {
  vertical-align: top;
  padding-top: 7px;
}

.form-field {
  width: 100%;
}

th {
  background-color: #e7e7e7;
}

th,td:not(.key-date):not(.extra-notes) {
  white-space: nowrap;
}

.table-container {
  overflow-x: scroll;
}

.inactive-row {
  font-style: italic;
  color: gray;
}

.remove-row {
  width: 30px;
}

.table-header-cell {
  display: flex;
  align-items: center;
  gap: 8px;
  height: 31px;
}

.sort-icons {
  display: flex;
  flex-direction: column;
}

.sort-icons i:hover {
  cursor: pointer;
}

.helper-text {
  margin-top: 0px;
}

.ErrorBoundary {
  padding-top: 100px;
  text-align: center;
}

@keyframes spin {
  from { transform: scale(1) rotate(0deg); }
  to { transform: scale(1) rotate(360deg); }
}

.LoaderButton .spinning.glyphicon {
  margin-right: 7px;
  top: 2px;
  animation: spin 1s infinite linear;
}

.container {
  padding-top: 100px;
  padding-bottom: 50px;
  padding-left: 10%;
  padding-right: 10%;
  min-height: 100vh;
  margin-bottom: -80px;
}

.container::after {
  content: "";
  display: block;
  height: 80px;
}

.App nav {
  position: fixed;
  top: 0;
  left: 0;
  width: 100vw;
  border-radius: 0;
  background-color: white;
  margin-bottom: 0px;
  z-index: 10;
}

.App nav .navbar-nav:not(.left-aligned) {
  text-align: right;
  padding-right: 15px;
}

.App nav .navbar-nav.left-aligned {
  text-align: left;
  padding-left: 15px;
}

.App nav .container-fluid {
  padding-left: 0px;
  padding-right: 0px;
}

.App nav .container-fluid .navbar-brand {
  margin-left: 0px;
  color: var(--red);
  font-weight: bold;
  font-size: 28px;
  padding: 10px 15px;
}

.App .navbar-brand img {
  height: 100%;
  margin-left: auto;
  margin-right: auto;
}

.App .nav .dropdown-toggle .fa-user-circle {
  font-size: 22px;
}

.App .nav .caret {
  display: none;
}

.App .nav p {
  margin-bottom: 0px;
}

.App .navbar-right .dropdown-menu a {
  text-align: right;
}

.App .nav .fa-user-circle {
  margin-left: 10px;
}

.App .nav.navbar-nav.navbar-right {
  position: fixed;
  top: 0;
  right: 0;
}

.App .navbar-default .navbar-nav>li>a {
  color: var(--navy);
}

.App .dropdown-menu>li>a {
  color: var(--navy);
}

.centered-content {
  display: flex;
  justify-content: center;
}

.centered-content-inner {
  width: 100%;
}

.centered-text {
  text-align: center;
}

.switch-current-season {
  margin-bottom: 15px;
}

.season-toggle {
  margin-bottom: 15px;
}

.download-schedule-link {
  margin-left: 15px;
  color: #337ab7;
}

.download-schedule-link i {
  margin-right: 5px;
}

.fa-check.updated-password {
  margin-right: 8px;
}

.reset-password-success {
  text-align: center;
}

.form-field-with-note td {
  padding-bottom: 0px;
}

.footer {
  height: 80px;
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  background-color: white;
}

.footer p {
  font-size: 14px;
  margin-bottom: 0px;
}

.footer a {
  text-decoration: underline;
}

.btn:not(.cancel-button):not(.danger-button) {
  background-color: var(--navy);
  border-color: var(--navy);
}

.home-section-1 {
  display: flex;
  justify-content: center;
  align-items: center;
  text-align: center;
  margin-top: 50px;
  padding: 60px;
}

.home-section-1-content {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  background-color: transparent;
}

.home-section-2 {
  background-color: white;
  width: 100vw;
  padding: 60px;
  text-align: center;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.home-section-2-intro {
  max-width: 830px;
  line-height: 1.25;
  padding-bottom: 30px;
}

.home-section-2-cards {
  display: flex;
  justify-content: center;
  align-items: flex-start;
  flex-wrap: wrap;
  margin-top: 30px;
  max-width: 100%;
}

.home-section-2-card {
  padding: 30px;
  border: solid 1px var(--navy);
  margin: 20px;
  width: min(320px, calc(100% - 40px));
  border-radius: 10px;
  background-color: var(--light-blue);
  box-shadow: -5px 5px 5px 0px #ccc;
}

.home-section-2-card h3 {
  margin-top: 0px;
}

.home-section-2-card p {
  margin-bottom: 0px;
}

.calendar-card, .leader-board-card {
  width: min(680px, calc(100% - 40px));
}

.weather-card {
  width: min(1040px, calc(100% - 40px));
}

.weather-card {
  text-align: center;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.home-page-leader-board {
  margin-bottom: 0px;
}

.home-page-leader-board th {
  text-align: center;
  background-color: var(--light-blue);
}

.key-dates {
  margin-bottom: 10px;
}

.home-page-calendar {
  margin-left: auto;
  margin-right: auto;
}

.key-date {
  text-align: left;
  vertical-align: top;
  padding-top: 10px;
  padding-bottom: 2px;
}

.key-date:not(.key-event) {
  padding-right: 20px;
}

.bold {
  font-weight: bold;
}

.key-date:last-of-type, .key-event:last-of-type {
  margin-bottom: 0px;
}

.season-calendars {
  display: flex;
  flex-wrap: wrap;
}

.season-events-container {
  margin: 25px;
  margin-top: 20px;
  border: solid 1px var(--navy);
  background-color: var(--light-blue);
  border-radius: 10px;
  padding: 30px;
  flex-basis: 0;
  flex-grow: 1;
  box-shadow: -5px 5px 5px 0px #ccc;
  min-width: 300px;
}

.season-events-container h3 {
  margin-top: 0px;
}

.season-events {
  display: flex;
  margin-top: 20px;
}

.weather {
  display: flex;
  justify-content: center;
  align-items: flex-start;
  flex-wrap: wrap;
  margin-bottom: 20px;
}

.weather-hour {
  padding: 20px;
}

.weather-hour h3 {
  margin-top: 10px;
}

.raindrop {
  margin-right: 7px;
}

.weather-external-link {
  margin-left: 10px;
}

h1, h2, h3, h4 {
  color: var(--navy);
}

h1.welcome {
  margin-top: 0px;
  margin-bottom: 5px;
  font-size: 4.5rem;
}

h3.welcome {
  margin-bottom: 5px;
}

.team-details-page-header {
  text-align: center;
  margin-bottom: 25px;
}

.team-details-page-break {
  margin-top: 45px;
  margin-bottom: 45px;
}

.team-registration {
  margin: auto;
  width: 100%;
  max-width: 393px;
}

.team-registration-modal .modal-content {
  margin: auto;
  padding: 32px 32px 20px;
  max-width: 489px;
}

.landing-img {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  object-fit: cover;
  z-index: -2;
}

.photo-overlay {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(255, 255, 255, 0.8);
  z-index: -1;
}

.NotFound {
  padding-top: 100px;
  text-align: center;
}

.link-below-button {
  margin-top: 10px;
  text-align: center;
}

.select-player {
  margin-bottom: 15px;
}

.select-player i {
  margin-left: 8px;
}

.checkbox {
  margin: 0px;
}

.line-header {
  margin-top: 12px;
  display: flex;
  width: 100%;
  justify-content: center;
  align-items: center;
  text-align: center;
  color: gray;
}

.line-header:before, .line-header:after {
  content: '';
  border-top: 1px solid gray;
  margin: 0 10px 0 0;
  flex: 1 0 10px;
}

.line-header:after {
  margin: 0 0 0 10px;
}

.committee-members div {
  margin-top: 20px;
  padding: 40px;
  background-color: var(--light-blue);
}

.committee-members h3 {
  margin-top: 0px;
}

.committee-member {
  margin-bottom: 0px;
}

.bylaw-child-item {
  margin-left: 20px;
}

.court-locations-intro, .tennis-centers-intro, .contact-intro, .schedules-intro, .match-result-intro, .standings-intro {
  margin-bottom: 25px;
}

.match-results-dropdown {
  margin-bottom: 10px;
}

.match-results-dropdown:last-of-type {
  margin-bottom: 25px;
}

.court-locations-list li {
  break-inside: avoid;
  display: flex;
}

.toggle-directions {
  width: 20px;
  height: 20px;
  margin-right: 10px;
}

.toggle-directions.invisible {
  visibility: hidden;
}

.no-margin-bottom {
  margin-bottom: 0px;
}

.white {
  color: white;
}

.help-block {
  padding-bottom: 0px !important;
}

.contact-item td {
  padding-bottom: 25px;
}

.contact-item-text {
  display: flex;
  flex-wrap: wrap;
}

.contact-item img {
  margin-right: 20px;
}

.publish-schedule-btn {
  margin-bottom: 15px;
}

.self-rating-info {
  margin-bottom: 0px;
}

.paypal-table {
  margin-bottom: 15px;
}

.paypal-form-label {
  padding-top: 10px;
}

.Signup form span.help-block {
  font-size: 14px;
  padding-bottom: 10px;
  color: #999;
}

.masonry-container {
  position: relative;
  width: 100%;
  max-width: 1200px;
}

.masonry-layout {
  width: 100%;
}

.full-width-modal .modal-dialog {
  min-width: min(90%, 1250px);
}

.rosters-locked {
  margin-bottom: 20px;
}

.clear-matches, .publish-matches {
  margin-top: 20px;
}

.clear-matches-note, .publish-matches-note {
  max-width: 650px;
  margin-left: auto;
  margin-right: auto;
}

.publish-matches-note {
  max-width: 400px;
}

@media all and (min-width: 768px) and (max-width: 1199px) {
  .home-section-2-card {
    width: 800px;
    max-width: calc(100% - 40px);
  }

  .weather-hour {
    padding: 0px;
  }
}

@media all and (max-width: 767px) {
  .navbar-brand {
    position: fixed;
    top: 0;
    left: calc(50% - 86px);
  }

  .navbar-toggle {
    float: left;
    margin-left: 30px;
  }

  .navbar-nav .open .dropdown-menu {
    background-color: white;
    border-left: solid 1px #eee;
    border-bottom: solid 1px #eee;
  }

  .container {
    padding-top: 50px;
    padding-bottom: 30px;
  }

  .season-events-container {
    margin: 10px;
  }

  .home-section-2 {
    padding: 40px;
  }

  .home-section-2-card {
    margin-left: 0px;
    margin-right: 0px;
    width: 100%;
  }

  .weather-hour {
    padding: 0px;
  }
}

@media all and (max-width: 699px) {
  .contact-item-text {
    display: block;
  }
}

@media all and  (min-width: 1000px) {
  .App .nav a {
    font-size: 18px;
  }

  .App nav ul {
    margin-top: 15px;
  }  

  .App nav .container-fluid .navbar-brand {
    height: 80px;
  }

  .home-section-1 {
    margin-top: 80px;
    padding: 80px;
  }
}

@media all and (min-width: 768px) {
  .court-locations-list {
    columns: 2;
    column-gap: 100px;
    list-style-type: none;
  }
}

@media all and (hover: hover) {
  .App .navbar-default .navbar-nav>li>a:hover {
    color: var(--red);
  }

  .App nav .container-fluid .navbar-brand:hover {
    color: var(--red);
  }

  .App .navbar-default .navbar-nav>.open>a, .navbar-default .navbar-nav>.open>a:focus, .navbar-default .navbar-nav>.open>a:hover {
    color: var(--navy);
  }

  .App .dropdown-menu>li>a:focus, .dropdown-menu>li>a:hover {
    background-color: var(--light-red);
  }

  .interactive-table td:hover {
    cursor: pointer;
  }

  .interactive-table tr.disabled td:hover {
    cursor: auto;
  }

  .btn:not(.cancel-button):not(.danger-button):hover {
    background-color: var(--navy);
    border-color: var(--navy);
  }

  .toggle-directions:hover {
    cursor: pointer;
  }

  .link:hover {
    cursor: pointer;
  }

  .download-schedule-link:hover {
    cursor: pointer;
    color: #23527c;
    text-decoration: underline;
  }
}
